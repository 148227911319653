import React, { useEffect, useState } from 'react';
import { Outlet, Link, useNavigate, useLocation } from 'react-router-dom';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Container, 
  Box, 
  Button,
  IconButton,
  Drawer,
  useTheme,
  useMediaQuery,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse
} from '@mui/material';
import { useAuth } from './AuthContext';
import logo from './Salon Logic Square 2.gif';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

// Import your existing icons
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BusinessIcon from '@mui/icons-material/Business';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import NoteIcon from '@mui/icons-material/Note';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';

const Layout = () => {
  const { user, logout, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [expandedItems, setExpandedItems] = useState({});

  useEffect(() => {
    if (!isAuthenticated || !user) {
      navigate('/login');
      return;
    }

    if (location.pathname === '/') {
      switch (user.role) {
        case 'ACCOUNT_MANAGER':
          navigate('/manager_dashboard');
          break;
        case 'ACCOUNT_OWNER':
          navigate('/owner_dashboard');
          break;
        case 'ACCOUNT_USER':
          navigate('/user_dashboard');
          break;
        default:
          navigate('/dashboard');
      }
    }
  }, [isAuthenticated, user, navigate, location.pathname]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login', { replace: true });
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleItemClick = (title) => {
    setExpandedItems(prev => ({
      ...prev,
      [title]: !prev[title]
    }));
  };

  if (!isAuthenticated || !user) {
    return null;
  }

  const menuItems = [
    { title: 'Dashboards', path: '/dashboard', icon: <DashboardIcon /> },
    {
      title: 'Employees',
      icon: <PeopleIcon />,
      items: [
        { title: 'Employee Card', path: '/employee_card' },
        { title: 'Submit Employee Review', path: '/submit_employee_review' },
        { title: 'Employee Reviews', path: '/employee_reviews' },
      ],
    },
    {
      title: 'Time Management',
      icon: <AccessTimeIcon />,
      items: [
        { title: 'Holiday Calendar', path: '/holiday_calendar' },
        { title: 'Request Holiday', path: '/submit_holiday_request' },
        { title: 'Submit Absence', path: '/submit_absence' },
        { title: 'Holiday Approval', path: '/holiday_approval' },
      ],
    },
    { title: 'Suppliers', path: '/supplier_card', icon: <BusinessIcon /> },
    { title: 'Income', path: '/income_card', icon: <CurrencyPoundIcon /> },
    { title: 'Notes', path: '/notes', icon: <NoteIcon /> },
    { title: 'Reminders', path: '/reminders', icon: <NotificationsIcon /> },
    { title: 'Space Rental', path: '/space_rental_card', icon: <HomeWorkIcon /> },
    { title: 'Employment Applications', path: '/employment_applications', icon: <WorkIcon /> },
    { title: 'Submit Training', path: '/submit_training', icon: <SchoolIcon /> },
  ];

  const SidebarContent = () => (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      height: '100%',
      backgroundColor: '#1976d2'
    }}>
      <Box sx={{ textAlign: 'center', p: 2 }}>
        <img 
          src={logo} 
          alt="Salon Logic Logo" 
          style={{ 
            maxWidth: '100%', 
            height: 'auto',
            maxHeight: '100px'
          }} 
        />
      </Box>
      <List sx={{ flexGrow: 1, overflowY: 'auto' }}>
        {menuItems.map((item, index) => (
          <Box key={index}>
            {item.items ? (
              <>
                <ListItem 
                  button 
                  onClick={() => handleItemClick(item.title)}
                  sx={{ color: 'white' }}
                >
                  <ListItemIcon sx={{ color: 'white' }}>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.title} />
                  {expandedItems[item.title] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={expandedItems[item.title]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.items.map((subItem, subIndex) => (
                      <ListItem 
                        button 
                        component={Link} 
                        to={subItem.path}
                        key={subIndex}
                        onClick={() => isMobile && setMobileOpen(false)}
                        sx={{ pl: 4, color: 'white' }}
                      >
                        <ListItemText primary={subItem.title} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </>
            ) : (
              <ListItem 
                button 
                component={Link} 
                to={item.path}
                onClick={() => isMobile && setMobileOpen(false)}
                sx={{ color: 'white' }}
              >
                <ListItemIcon sx={{ color: 'white' }}>{item.icon}</ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItem>
            )}
          </Box>
        ))}
      </List>
      <Button 
        onClick={handleLogout} 
        variant="contained" 
        color="secondary" 
        sx={{ m: 2 }}
      >
        Logout
      </Button>
    </Box>
  );

  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <AppBar position="static" sx={{ 
        borderRadius: { xs: 0, sm: '12px' }, 
        margin: { xs: 0, sm: '16px 16px 0 16px' },
        backgroundColor: '#1976d2'
      }}>
        <Toolbar>
          {isMobile && (
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Salon Management System
          </Typography>
          {user && (
            <Typography 
              variant="subtitle1" 
              sx={{ 
                display: { xs: 'none', sm: 'block' }
              }}
            >
              Welcome, {user.email}
            </Typography>
          )}
        </Toolbar>
      </AppBar>

      <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden', margin: { xs: 0, sm: '16px' } }}>
        {/* Mobile Drawer */}
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { width: 250 },
          }}
        >
          <SidebarContent />
        </Drawer>

        {/* Desktop Sidebar */}
        <Box sx={{ 
          width: 250,
          display: { xs: 'none', sm: 'block' },
          backgroundColor: '#1976d2',
          borderRadius: '12px 0 0 12px',
        }}>
          <SidebarContent />
        </Box>

        {/* Main Content */}
        <Box sx={{ 
          flexGrow: 1,
          backgroundColor: '#e3f2fd',
          borderRadius: { xs: 0, sm: '0 12px 12px 0' },
          overflow: 'auto',
          padding: '16px'
        }}>
          <Outlet />
        </Box>
      </Box>

      <Box 
        component="footer" 
        sx={{ 
          py: 3, 
          px: 2, 
          backgroundColor: theme => theme.palette.grey[200],
          borderRadius: { xs: 0, sm: '12px' },
          margin: { xs: 0, sm: '0 16px 16px 16px' }
        }}
      >
        <Container maxWidth="sm">
          <Typography variant="body2" color="text.secondary" align="center">
            © {new Date().getFullYear()} Salon Management System
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};

export default Layout;