import errorHandler from './utils/errorHandler';
import axios from 'axios';

// Create axios instance
const api = axios.create({
    baseURL: '/api',
    headers: {
        'Content-Type': 'application/json'
    }
});

// Logging utility
const logApiCall = (config) => {
    if (process.env.NODE_ENV !== 'production') {
        console.log('API Request:', {
            method: config.method,
            url: config.url,
            baseURL: config.baseURL,
            fullURL: `${config.baseURL}${config.url}`,
            hasToken: !!localStorage.getItem('token')
        });
    }
};

// Error handling utilities
const handleAuthError = () => {
    localStorage.clear();
    window.location.href = '/login';
};

const handleResponseError = (error) => {
    if (process.env.NODE_ENV !== 'production') {
        console.error('API Error:', {
            status: error.response?.status,
            message: error.message,
            url: error.config?.url
        });
    }
};

// Request interceptor
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        
        logApiCall(config);
        return config;
    },
    (error) => {
        if (process.env.NODE_ENV !== 'production') {
            console.error('Request error:', error);
        }
        return Promise.reject(error);
    }
);

// Response interceptor
api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                const refreshToken = localStorage.getItem('refresh_token');
                const response = await axios.post('/api/auth/refresh', {
                    refresh_token: refreshToken
                });

                if (response.data.token) {
                    localStorage.setItem('token', response.data.token);
                    originalRequest.headers.Authorization = `Bearer ${response.data.token}`;
                    return axios(originalRequest);
                }
            } catch (refreshError) {
                handleAuthError();
                return Promise.reject(refreshError);
            }
        }

        handleResponseError(error);
        return Promise.reject(error);
    }
);

export default api;