import React, { useState, useEffect } from 'react';
import {
 Paper,
 Table,
 TableBody,
 TableCell,
 TableContainer,
 TableHead,
 TableRow,
 TablePagination,
 Button,
 Dialog,
 DialogTitle,
 DialogContent,
 DialogActions,
 Typography,
 Box,
 CircularProgress,
 Select,
 MenuItem,
 FormControl,
 Snackbar,
 Alert
} from '@mui/material';
import api from './axiosConfig';

const ManageApplications = () => {
 const [applications, setApplications] = useState([]);
 const [loading, setLoading] = useState(true);
 const [page, setPage] = useState(0);
 const [rowsPerPage, setRowsPerPage] = useState(10);
 const [selectedApplication, setSelectedApplication] = useState(null);
 const [openDialog, setOpenDialog] = useState(false);
 const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

 useEffect(() => {
   fetchApplications();
 }, []);

 const fetchApplications = async () => {
   try {
     const response = await api.get('/applications');
     setApplications(response.data || []);
   } catch (error) {
     setSnackbar({
       open: true,
       message: 'Error loading applications',
       severity: 'error'
     });
   } finally {
     setLoading(false);
   }
 };

 const handleStatusChange = async (applicationId, newStatus) => {
   try {
     await api.put(`/applications/${applicationId}/status`, { status: newStatus });
     setSnackbar({
       open: true,
       message: 'Status updated successfully',
       severity: 'success'
     });
     await fetchApplications();
   } catch (error) {
     setSnackbar({
       open: true,
       message: 'Error updating status',
       severity: 'error'
     });
   }
 };

 const handleConvertToEmployee = async (applicationId) => {
   try {
     await api.post(`/applications/${applicationId}/convert`);
     setSnackbar({
       open: true,
       message: 'Successfully converted to employee',
       severity: 'success'
     });
     await fetchApplications();
     setOpenDialog(false);
   } catch (error) {
     setSnackbar({
       open: true,
       message: 'Error converting to employee',
       severity: 'error'
     });
   }
 };

 const handleCloseSnackbar = () => setSnackbar(prev => ({ ...prev, open: false }));
 
 const handleViewDetails = (application) => {
   setSelectedApplication(application);
   setOpenDialog(true);
 };

 if (loading) {
   return (
     <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
       <CircularProgress />
     </Box>
   );
 }

 return (
   <Paper sx={{ width: '100%', overflow: 'hidden', p: 3 }}>
     <Typography variant="h5" gutterBottom>
       Manage Applications
     </Typography>

     {applications.length === 0 ? (
       <Typography>No applications found</Typography>
     ) : (
       <>
         <TableContainer>
           <Table stickyHeader>
             <TableHead>
               <TableRow>
                 <TableCell>Date</TableCell>
                 <TableCell>Name</TableCell>
                 <TableCell>Position</TableCell>
                 <TableCell>Email</TableCell>
                 <TableCell>Status</TableCell>
                 <TableCell>Actions</TableCell>
               </TableRow>
             </TableHead>
             <TableBody>
               {applications
                 .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                 .map((application) => (
                   <TableRow key={application.id}>
                     <TableCell>{new Date(application.application_date).toLocaleDateString()}</TableCell>
                     <TableCell>{application.applicant_name}</TableCell>
                     <TableCell>{application.position_applied}</TableCell>
                     <TableCell>{application.email}</TableCell>
                     <TableCell>
                       <FormControl size="small" fullWidth>
                         <Select
                           value={application.status || 'PENDING'}
                           onChange={(e) => handleStatusChange(application.id, e.target.value)}
                         >
                           <MenuItem value="PENDING">Pending</MenuItem>
                           <MenuItem value="ACCEPTED">Accepted</MenuItem>
                           <MenuItem value="REJECTED">Rejected</MenuItem>
                           <MenuItem value="HIRED">Hired</MenuItem>
                         </Select>
                       </FormControl>
                     </TableCell>
                     <TableCell>
                       <Button
                         size="small"
                         onClick={() => handleViewDetails(application)}
                       >
                         View Details
                       </Button>
                     </TableCell>
                   </TableRow>
                 ))}
             </TableBody>
           </Table>
         </TableContainer>

         <TablePagination
           component="div"
           count={applications.length}
           rowsPerPage={rowsPerPage}
           page={page}
           onPageChange={(event, newPage) => setPage(newPage)}
           onRowsPerPageChange={(event) => {
             setRowsPerPage(parseInt(event.target.value, 10));
             setPage(0);
           }}
         />
       </>
     )}

     <Dialog
       open={openDialog}
       onClose={() => setOpenDialog(false)}
       maxWidth="md"
       fullWidth
     >
       {selectedApplication && (
         <>
           <DialogTitle>
             Application Details - {selectedApplication.applicant_name}
           </DialogTitle>
           <DialogContent>
             <Box sx={{ mt: 2 }}>
               <Typography variant="subtitle1" gutterBottom>Personal Information</Typography>
               <Typography>Email: {selectedApplication.email}</Typography>
               <Typography>Phone: {selectedApplication.phone}</Typography>
               <Typography>Address: {selectedApplication.address}</Typography>

               <Typography variant="subtitle1" sx={{ mt: 2 }} gutterBottom>Experience</Typography>
               <Typography>Current Employer: {selectedApplication.current_employer || 'N/A'}</Typography>
               <Typography>Current Role: {selectedApplication.current_role || 'N/A'}</Typography>
               <Typography>Qualifications: {selectedApplication.relevant_qualifications || 'N/A'}</Typography>

               <Typography variant="subtitle1" sx={{ mt: 2 }} gutterBottom>References</Typography>
               <Typography>Reference 1: {selectedApplication.reference1_name}</Typography>
               <Typography>Position: {selectedApplication.reference1_position}</Typography>
               <Typography>Contact: {selectedApplication.reference1_contact}</Typography>
               <Typography sx={{ mt: 1 }}>Reference 2: {selectedApplication.reference2_name}</Typography>
               <Typography>Position: {selectedApplication.reference2_position}</Typography>
               <Typography>Contact: {selectedApplication.reference2_contact}</Typography>
             </Box>
           </DialogContent>
           <DialogActions>
             <Button onClick={() => setOpenDialog(false)}>Close</Button>
             {selectedApplication.status === 'ACCEPTED' && (
               <Button
                 color="primary"
                 onClick={() => handleConvertToEmployee(selectedApplication.id)}
               >
                 Convert to Employee
               </Button>
             )}
           </DialogActions>
         </>
       )}
     </Dialog>

     <Snackbar
       open={snackbar.open}
       autoHideDuration={6000}
       onClose={handleCloseSnackbar}
       anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
     >
       <Alert
         onClose={handleCloseSnackbar}
         severity={snackbar.severity}
         sx={{ width: '100%' }}
       >
         {snackbar.message}
       </Alert>
     </Snackbar>
   </Paper>
 );
};

export default ManageApplications;