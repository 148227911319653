import errorHandler from './utils/errorHandler';
import React, { useState, useEffect, useCallback } from 'react';
import api from './axiosConfig';
import { 
  Box, 
  Typography, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Button,
  Snackbar,
  Alert,
  CircularProgress
} from '@mui/material';

const HolidayApproval = () => {
  const [holidays, setHolidays] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  const showSnackbar = useCallback((message, severity) => {
    setSnackbar({ open: true, message, severity });
  }, []);

  const fetchHolidays = useCallback(async () => {
  try {
    setLoading(true);
    errorHandler.logApiCall('Fetching pending holidays');
    const response = await api.get('/holidays/pending');
    errorHandler.logDebug('HolidayApproval', 'Pending holidays response:', response.data);
    setHolidays(response.data);
  } catch (error) {
    console.error('Error fetching holidays:', error.response?.data || error.message);
    showSnackbar('Error fetching holidays', 'error');
  } finally {
    setLoading(false);
  }
}, [showSnackbar]);

  useEffect(() => {
    fetchHolidays();
  }, [fetchHolidays]);

  const handleApproval = async (id, status) => {
    try {
      await api.post(`/holidays/${id}/${status.toLowerCase()}`);
      showSnackbar(`Holiday request ${status.toLowerCase()}ed`, 'success');
      fetchHolidays();
    } catch (error) {
      console.error('Error updating holiday status:', error);
      showSnackbar('Error updating holiday status', 'error');
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box 
      sx={{ 
        p: 3,
        mt: 3,
        maxWidth: 1200,
        mx: 'auto',
        border: '1px solid #ccc',
        borderRadius: 2,
        boxShadow: 1
      }}
    >
      <Typography variant="h4" gutterBottom>Holiday Approval</Typography>
      {holidays.length === 0 ? (
        <Typography>No pending holiday requests.</Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Employee</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell>Days</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {holidays.map((holiday) => (
                <TableRow key={holiday.id}>
                  <TableCell>{holiday.employee_name}</TableCell>
                  <TableCell>{new Date(holiday.start_date).toLocaleDateString()}</TableCell>
                  <TableCell>{new Date(holiday.end_date).toLocaleDateString()}</TableCell>
                  <TableCell>{holiday.number_of_days_holiday}</TableCell>
                  <TableCell>{holiday.status}</TableCell>
                  <TableCell>
                    <Box display="flex" gap={1}>
                      <Button 
                        onClick={() => handleApproval(holiday.id, 'APPROVE')} 
                        color="primary"
                        variant="contained"
                        size="small"
                      >
                        Approve
                      </Button>
                      <Button 
                        onClick={() => handleApproval(holiday.id, 'DENY')} 
                        color="secondary"
                        variant="contained"
                        size="small"
                      >
                        Deny
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        <Alert 
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default HolidayApproval;