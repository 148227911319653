import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false, 
      error: null, 
      errorInfo: null 
    };
  }

  static getDerivedStateFromError(error) {
    return { 
      hasError: true,
      error: error 
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
    
    // Only log errors in development
    if (process.env.NODE_ENV !== 'production') {
      console.error('ErrorBoundary caught an error:', error, errorInfo);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={styles.container}>
          <h1 style={styles.heading}>Something went wrong</h1>
          {process.env.NODE_ENV !== 'production' && (
            <details style={styles.details}>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo && this.state.errorInfo.componentStack}
            </details>
          )}
          <button 
            onClick={() => window.location.reload()}
            style={styles.button}
          >
            Refresh Page
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

const styles = {
  container: {
    padding: '20px',
    margin: '20px',
    border: '1px solid #f5c6cb',
    borderRadius: '4px',
    backgroundColor: '#f8d7da',
    color: '#721c24',
    textAlign: 'center'
  },
  heading: {
    marginBottom: '20px',
    fontSize: '24px'
  },
  details: {
    whiteSpace: 'pre-wrap',
    marginBottom: '20px',
    textAlign: 'left',
    padding: '10px',
    backgroundColor: '#fff',
    borderRadius: '4px'
  },
  button: {
    padding: '8px 16px',
    backgroundColor: '#dc3545',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
    '&:hover': {
      backgroundColor: '#c82333'
    }
  }
};

export default ErrorBoundary;