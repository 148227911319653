import React from 'react';
import { Box, TextField } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';

const DatePickerComponent = ({ startDate, endDate, setStartDate, setEndDate }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <Box sx={{ 
        display: 'flex', 
        flexDirection: { xs: 'column', sm: 'row' },
        gap: { xs: 2, sm: 3 },
        mb: 2,
        width: '100%'
      }}>
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={(newValue) => setStartDate(dayjs(newValue))}
          renderInput={(params) => (
            <TextField 
              {...params} 
              fullWidth
              sx={{ 
                '& .MuiInputBase-root': {
                  height: { xs: '48px', sm: '56px' }
                }
              }}
            />
          )}
          inputFormat="DD/MM/YYYY"
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={(newValue) => setEndDate(dayjs(newValue))}
          renderInput={(params) => (
            <TextField 
              {...params} 
              fullWidth
              sx={{ 
                '& .MuiInputBase-root': {
                  height: { xs: '48px', sm: '56px' }
                }
              }}
            />
          )}
          inputFormat="DD/MM/YYYY"
        />
      </Box>
    </LocalizationProvider>
  );
};

export default DatePickerComponent;