import errorHandler from './utils/errorHandler';
import React, { useState } from 'react';
import { 
  Button, 
  TextField, 
  Grid, 
  Typography, 
  LinearProgress, 
  Alert,
  AlertTitle, 
  Box,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { useNavigate } from 'react-router-dom'; 
import Papa from 'papaparse';
import api from './axiosConfig';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import UploadIcon from '@mui/icons-material/Upload';

const Import = ({ onDataImported }) => {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);
  const [showMapping, setShowMapping] = useState(false);
  const [columnMapping, setColumnMapping] = useState({});
  const [importType, setImportType] = useState('standard'); // 'standard' or 'bank'

  // Define the expected fields and their descriptions
  const expectedFields = {
    date: { required: true, description: 'Transaction date (DD/MM/YYYY)', example: '12/12/2024', aliases: ['Date', 'Transaction Date', 'Posted Date'] },
    type: { required: true, description: 'Transaction type', example: 'Purchase', aliases: ['Type', 'Transaction Type'] },
    supplier_name: { required: true, description: 'Name of supplier', example: 'ABC Ltd', aliases: ['Supplier', 'Payee', 'Description'] },
    category: { required: false, description: 'Expense category', example: 'Office Supplies', aliases: ['Category', 'Type'] },
    description: { required: false, description: 'Transaction description', example: 'Monthly stationary order', aliases: ['Description', 'Notes', 'Reference'] },
    total: { required: true, description: 'Total amount (including VAT)', example: '120.00', aliases: ['Amount', 'Total', 'Debit Amount'] },
    vat: { required: false, description: 'VAT amount', example: '20.00', aliases: ['VAT', 'Tax'] },
    net_expenditure: { required: true, description: 'Net amount (excluding VAT)', example: '100.00', aliases: ['Net', 'Net Amount'] },
    payment_type: { required: true, description: 'Payment method (CASH/CARD/BANK)', example: 'CARD', aliases: ['Payment Method', 'Method'] }
  };

  // Bank statement specific mappings
  const bankStatementFields = {
    date: { aliases: ['Transaction Date', 'Date', 'Posted Date'] },
    description: { aliases: ['Description', 'Transaction Description', 'Details'] },
    debit: { aliases: ['Debit', 'Debit Amount', 'Paid Out'] },
    credit: { aliases: ['Credit', 'Credit Amount', 'Paid In'] },
    balance: { aliases: ['Balance', 'Running Balance'] }
  };

  const handleImportTypeChange = (event) => {
    setImportType(event.target.value);
    setColumnMapping({}); // Reset mapping when type changes
  };

  const suggestMappings = (headers) => {
    const suggestions = {};
    headers.forEach(header => {
      const lowerHeader = header.toLowerCase();
      // Check against main fields and their aliases
      Object.entries(expectedFields).forEach(([fieldName, field]) => {
        const allAliases = [fieldName, ...field.aliases].map(a => a.toLowerCase());
        if (allAliases.includes(lowerHeader)) {
          suggestions[header] = fieldName;
        }
      });
      
      // If bank statement import, check against bank specific fields
      if (importType === 'bank') {
        Object.entries(bankStatementFields).forEach(([fieldName, field]) => {
          const allAliases = [fieldName, ...field.aliases].map(a => a.toLowerCase());
          if (allAliases.includes(lowerHeader)) {
            suggestions[header] = fieldName;
          }
        });
      }
    });
    return suggestions;
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setError(null);
    setResult(null);
    
    Papa.parse(selectedFile, {
      header: true,
      complete: (results) => {
        if (results.data && results.data.length > 0) {
          setCsvData(results.data);
          const suggestedMappings = suggestMappings(Object.keys(results.data[0]));
          setColumnMapping(suggestedMappings);
          setShowMapping(true);
        }
      },
      error: (err) => {
        setError('Error parsing CSV file: ' + err.message);
      }
    });
  };

  const handleMappingChange = (csvColumn, systemField) => {
    setColumnMapping(prev => ({
      ...prev,
      [csvColumn]: systemField
    }));
  };

 const handleUpload = async () => {
    try {
      setError(null);
      setResult(null);
      setUploadProgress(0);

      if (!file) {
        setError('Please select a file first');
        return;
      }

      // Debug log the data being sent
      errorHandler.logDebug('Import', 'File being uploaded:', file.name);
      errorHandler.logDebug('Import', 'Column mappings:', columnMapping);
      errorHandler.logDebug('Import', 'Import type:', importType);

      const formData = new FormData();
      formData.append('file', file);
      formData.append('mappings', JSON.stringify(columnMapping));
      formData.append('importType', importType);

      const response = await api.post('/expenditures/import', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          setUploadProgress(progress);
          errorHandler.logDebug('Import', `Upload progress: ${progress}%`);
        },
      });

      errorHandler.logApiCall('Response from server:', response.data);

      setResult(response.data);
      
      if (response.data.supplier_warnings?.length > 0) {
        errorHandler.logDebug('Import', 'Supplier warnings:', response.data.supplier_warnings);
      }

      if (onDataImported) {
        onDataImported(response.data);
      }

    } catch (err) {
      console.error('Upload error details:', {
        message: err.message,
        response: err.response?.data,
        status: err.response?.status,
        fullError: err
      });
      
      // Show more detailed error to user
      setError(
        err.response?.data?.error || 
        err.response?.data?.message || 
        err.message || 
        'Error uploading file'
      );
    }
};
    
const generateTemplate = () => {
    const template = [
      {
        date: '12/12/2024',
        type: 'Purchase',
        supplier_name: 'ABC Ltd',
        category: 'Office Supplies',
        description: 'Monthly stationary order',
        total: '120.00',
        vat: '20.00',
        net_expenditure: '100.00',
        payment_type: 'CARD'
      }
    ];
    
    const csv = Papa.unparse(template);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'expenditure_import_template.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
};

  return (
    <Grid container spacing={3}>
      {/* Import Type Selection */}
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel>Import Type</InputLabel>
          <Select
            value={importType}
            onChange={handleImportTypeChange}
            label="Import Type"
          >
            <MenuItem value="standard">Standard Import</MenuItem>
            <MenuItem value="bank">Bank Statement</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      {/* Template Section */}
      <Grid item xs={12}>
  <Box sx={{ mb: 3 }}>
    <Typography variant="h6" gutterBottom>
      Import Instructions
    </Typography>
    <Alert severity="info" sx={{ mb: 2 }}>
      <AlertTitle>Before You Import</AlertTitle>
      Please ensure all suppliers are added to the system before importing expenditures.
      <br />
      <Button 
        size="small" 
        color="primary" 
        onClick={() => navigate('/suppliers')} 
        sx={{ mt: 1 }}
      >
        Manage Suppliers
      </Button>
    </Alert>
    <Typography variant="body2" gutterBottom>
      Download the template below and fill in your expenditure data. Required fields are marked with *.
    </Typography>
    <Button 
      variant="outlined" 
      startIcon={<FileDownloadIcon />}
      onClick={generateTemplate}
      sx={{ mt: 1 }}
    >
      Download Template
    </Button>
  </Box>
</Grid>

      {/* File Upload Section */}
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Import Data
        </Typography>
        <input
          accept=".csv"
          style={{ display: 'none' }}
          id="raised-button-file"
          type="file"
          onChange={handleFileChange}
        />
        <label htmlFor="raised-button-file">
          <Button 
            variant="contained" 
            component="span"
            startIcon={<UploadIcon />}
          >
            Choose CSV File
          </Button>
        </label>
        {file && (
          <Typography variant="body2" sx={{ mt: 1 }}>
            Selected file: {file.name}
          </Typography>
        )}
      </Grid>

      {/* Column Mapping Dialog */}
      <Dialog open={showMapping} maxWidth="md" fullWidth>
        <DialogTitle>Map Columns</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {Object.keys(columnMapping).map((csvColumn) => (
              <Grid item xs={12} sm={6} key={csvColumn}>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel>{`Map "${csvColumn}" to:`}</InputLabel>
                  <Select
                    value={columnMapping[csvColumn] || ''}
                    onChange={(e) => handleMappingChange(csvColumn, e.target.value)}
                    label={`Map "${csvColumn}" to:`}
                  >
                    <MenuItem value="">Skip this column</MenuItem>
                    {Object.entries(expectedFields).map(([field, info]) => (
                      <MenuItem key={field} value={field}>
                        {field} {info.required && '*'}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowMapping(false)}>Cancel</Button>
          <Button onClick={handleUpload} variant="contained" color="primary">
            Import Data
          </Button>
        </DialogActions>
      </Dialog>

      {/* Error and Result Messages */}
      {error && (
        <Grid item xs={12}>
          <Alert severity="error">{error}</Alert>
        </Grid>
      )}

      {result && (
        <Grid item xs={12}>
          <Alert severity="success">
            Successfully imported {result.successful} out of {result.total_processed} records
          </Alert>
        </Grid>
      )}

      {/* Progress Indicator */}
      {uploadProgress > 0 && (
        <Grid item xs={12}>
          <Box sx={{ width: '100%', mt: 2 }}>
            <LinearProgress variant="determinate" value={uploadProgress} />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default Import;