import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import api from './axiosConfig';
import { Container, Typography } from '@mui/material';
import './HolidayCalendar.css';  // Import the CSS file

const HolidayCalendar = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    fetchHolidays();
  }, []);

  const fetchHolidays = async () => {
    try {
      const response = await api.get('/holidays');  
      const formattedEvents = response.data.map(holiday => ({
        title: `${holiday.employee_name} - ${holiday.approved ? 'Approved' : 'Pending'}`,
        start: holiday.start_date,
        end: holiday.end_date,
        color: holiday.approved ? 'green' : 'orange'
      }));
      setEvents(formattedEvents);
    } catch (error) {
      console.error('Error fetching holidays:', error);
    }
  };

  const handleDateClick = (arg) => {
    
  };

  return (
    <Container maxWidth="lg" style={{ marginLeft: '200px', marginTop: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Holiday Calendar
      </Typography>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={events}
        dateClick={handleDateClick}
      />
    </Container>
  );
};

export default HolidayCalendar;
