import errorHandler from './utils/errorHandler';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Paper,
  Typography,
  Grid,
  Tabs,
  Tab,
  Box,
  CircularProgress,
  Alert,
  Snackbar,
  Checkbox,
  FormHelperText
} from '@mui/material';
import api from './axiosConfig';

const schema = yup.object().shape({
  position: yup.string().required('Please specify the position you are applying for'),
  firstName: yup.string().required('First name is required'),
  surname: yup.string().required('Surname is required'),
  address: yup.string().required('Address is required'),
  contact_number: yup.string().required('Contact number is required'),
  email: yup.string().email('Please enter a valid email').required('Email is required'),
  rightToWork: yup.string().required('Please indicate your right to work in the UK'),
  
  availableStartDate: yup.string().required('Please indicate when you could start'),
  
  availability: yup.object({
    monday: yup.boolean(),
    tuesday: yup.boolean(),
    wednesday: yup.boolean(),
    thursday: yup.boolean(),
    friday: yup.boolean(),
    saturday: yup.boolean(),
    sunday: yup.boolean()
  }).test(
    'at-least-one-day',
    'Please select at least one available day',
    obj => Object.values(obj).some(val => val === true)
  ),
  
  preferredHours: yup.string().required('Please indicate your preferred working hours'),
  
  // Make these optional but include them in the schema
  relevantQualifications: yup.string(),
  currentEmployer: yup.string(),
  currentRole: yup.string(),
  experienceSummary: yup.string(),
  reasonForLeaving: yup.string(),
  
  reference1: yup.object({
    name: yup.string().required('Reference name is required'),
    position: yup.string().required('Reference position is required'),
    contact: yup.string().required('Reference contact details are required'),
    relationship: yup.string().required('Please specify your relationship to the referee')
  }),
  reference2: yup.object({
    name: yup.string().required('Reference name is required'),
    position: yup.string().required('Reference position is required'),
    contact: yup.string().required('Reference contact details are required'),
    relationship: yup.string().required('Please specify your relationship to the referee')
  }),
  
  canContactCurrentEmployer: yup.string().required('Please indicate if we can contact your current employer'),
  declaration: yup.boolean()
    .oneOf([true], 'You must agree to the declaration'),
  declarationDate: yup.string()
    .required('Please date your declaration')
});

const EmploymentApplicationForm = () => {
  const { applicationUrlId } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [salonDetails, setSalonDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const { 
    control, 
    handleSubmit, 
    formState: { errors, isSubmitting },
    trigger,
    watch 
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      availability: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false
      },
      declaration: false
    }
  });

  // Debug logs
  useEffect(() => {
    errorHandler.logDebug('EmploymentApplicationForm', 'Current form errors:', errors);
  }, [errors]);

  useEffect(() => {
    const fetchSalonDetails = async () => {
      try {
        errorHandler.logApiCall('Fetching salon details for ID:', applicationUrlId);
        const response = await api.get(`/apply/${applicationUrlId}`);
        setSalonDetails(response.data);
      } catch (error) {
        console.error('Error fetching salon details:', error);
        setSnackbar({
          open: true,
          message: 'Error loading salon details. Please try again later.',
          severity: 'error'
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchSalonDetails();
  }, [applicationUrlId]);

  const onSubmit = async (data) => {
    errorHandler.logDebug('EmploymentApplicationForm', 'Form submission initiated with data:', data);
    try {
      const formattedData = {
        first_name: data.firstName,
        surname: data.surname,
        email: data.email,
        contact_number: data.contact_number,
        position: data.position,
        address: data.address,
        right_to_work: data.rightToWork === 'yes',
        available_start_date: data.availableStartDate,
        preferred_hours: data.preferredHours,
        availability: data.availability,
        relevant_qualifications: data.relevantQualifications || '',
        current_employer: data.currentEmployer || '',
        current_role: data.currentRole || '',
        experience_summary: data.experienceSummary || '',
        reference1_name: data.reference1.name,
        reference1_position: data.reference1.position,
        reference1_contact: data.reference1.contact,
        reference1_relationship: data.reference1.relationship,
        reference2_name: data.reference2.name,
        reference2_position: data.reference2.position,
        reference2_contact: data.reference2.contact,
        reference2_relationship: data.reference2.relationship,
        can_contact_current_employer: data.canContactCurrentEmployer === 'yes',
        declaration_signed: data.declaration,
        declaration_date: data.declarationDate
      };

      errorHandler.logDebug('EmploymentApplicationForm', 'Sending formatted data to server:', formattedData);

      const response = await api.post(`/apply/${applicationUrlId}`, formattedData);
      errorHandler.logDebug('EmploymentApplicationForm', 'Server response:', response.data);

      setSnackbar({
        open: true,
        message: 'Application submitted successfully!',
        severity: 'success'
      });
    } catch (error) {
      console.error('Submission error:', error);
      setSnackbar({
        open: true,
        message: error.response?.data?.error || 'Error submitting application. Please try again.',
        severity: 'error'
      });
    }
  };

  const handleTabChange = async (event, newValue) => {
    // Validate current tab before allowing to move forward
    const fields = getFieldsForTab(activeTab);
    const isValid = await trigger(fields);
    
    if (newValue > activeTab && !isValid) {
      setSnackbar({
        open: true,
        message: 'Please fill in all required fields correctly before proceeding',
        severity: 'error'
      });
      return;
    }
    
    setActiveTab(newValue);
  };

  const getFieldsForTab = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        return ['position', 'firstName', 'surname', 'address', 'contact_number', 'email', 'rightToWork'];
      case 1:
        return ['availableStartDate', 'availability', 'preferredHours'];
      case 2:
        return ['relevantQualifications', 'currentEmployer', 'currentRole', 'experienceSummary'];
      case 3:
        return [
          'reference1.name', 'reference1.position', 'reference1.contact', 'reference1.relationship',
          'reference2.name', 'reference2.position', 'reference2.contact', 'reference2.relationship',
          'canContactCurrentEmployer', 'declaration', 'declarationDate'
        ];
      default:
        return [];
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!salonDetails) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography variant="h6">Invalid application link</Typography>
      </Box>
    );
  }

  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: 800, margin: '0 auto', mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        Employment Application - {salonDetails.salon_name}
      </Typography>

      <Tabs value={activeTab} onChange={handleTabChange} sx={{ mb: 3 }}>
        <Tab label="Personal Details" />
        <Tab label="Availability" />
        <Tab label="Experience" />
        <Tab label="References" />
      </Tabs>

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        {/* Personal Details Tab */}
        {activeTab === 0 && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="position"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Position Applied For"
                    error={!!errors.position}
                    helperText={errors.position?.message}
                  />
                )}
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <Controller
                name="firstName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="First Name"
                    error={!!errors.firstName}
                    helperText={errors.firstName?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="surname"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Surname"
                    error={!!errors.surname}
                    helperText={errors.surname?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="address"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    rows={3}
                    label="Address"
                    error={!!errors.address}
                    helperText={errors.address?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="contact_number"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Contact Number"
                    error={!!errors.contact_number}
                    helperText={errors.contact_number?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type="email"
                    label="Email"
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="rightToWork"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Box>
                    <Typography variant="subtitle2" gutterBottom>
                      Do you have the right to work in the UK?
                    </Typography>
                    <RadioGroup {...field} row>
                      <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="no" control={<Radio />} label="No" />
                    </RadioGroup>
                    {errors.rightToWork && (
                      <FormHelperText error>{errors.rightToWork.message}</FormHelperText>
                    )}
                  </Box>
                )}
              />
            </Grid>
          </Grid>
        )}

        {/* Availability Tab */}
        {activeTab === 1 && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Available Days
              </Typography>
              {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map((day) => (
                <Controller
                  key={day}
                  name={`availability.${day}`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={value}
                          onChange={(e) => onChange(e.target.checked)}
                        />
                      }
                      label={day.charAt(0).toUpperCase() + day.slice(1)}
                    />
                  )}
                />
              ))}
              {errors.availability && (
                <FormHelperText error>{errors.availability.message}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="preferredHours"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    rows={2}
                    label="Preferred Working Hours"
                    error={!!errors.preferredHours}
                    helperText={errors.preferredHours?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="availableStartDate"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type="date"
                    label="When could you start?"
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.availableStartDate}
                    helperText={errors.availableStartDate?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        )}

        {/* Experience Tab */}
        {activeTab === 2 && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="relevantQualifications"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    rows={3}
                    label="Relevant Qualifications"
                    helperText="List any relevant qualifications or certifications"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
              name="experienceSummary"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    rows={4}
                    label="Experience Summary"
                    helperText="Summarize your relevant work experience"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="currentEmployer"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Current/Most Recent Employer"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="currentRole"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Current/Most Recent Role"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="reasonForLeaving"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Reason for Leaving"
                  />
                )}
              />
            </Grid>
          </Grid>
        )}

        {/* References Tab */}
        {activeTab === 3 && (
          <Grid container spacing={2}>
            {[1, 2].map((refNum) => (
              <React.Fragment key={refNum}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    Reference {refNum}
                  </Typography>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <Controller
                    name={`reference${refNum}.name`}
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Name"
                        error={!!errors[`reference${refNum}`]?.name}
                        helperText={errors[`reference${refNum}`]?.name?.message}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Controller
                    name={`reference${refNum}.position`}
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Position"
                        error={!!errors[`reference${refNum}`]?.position}
                        helperText={errors[`reference${refNum}`]?.position?.message}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name={`reference${refNum}.contact`}
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Contact Details"
                        error={!!errors[`reference${refNum}`]?.contact}
                        helperText={errors[`reference${refNum}`]?.contact?.message}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name={`reference${refNum}.relationship`}
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Relationship to Referee"
                        error={!!errors[`reference${refNum}`]?.relationship}
                        helperText={errors[`reference${refNum}`]?.relationship?.message}
                      />
                    )}
                  />
                </Grid>
              </React.Fragment>
            ))}

            <Grid item xs={12}>
              <Controller
                name="canContactCurrentEmployer"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Box>
                    <Typography variant="subtitle2" gutterBottom>
                      Can we contact your current employer?
                    </Typography>
                    <RadioGroup {...field} row>
                      <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="no" control={<Radio />} label="No" />
                    </RadioGroup>
                    {errors.canContactCurrentEmployer && (
                      <FormHelperText error>{errors.canContactCurrentEmployer.message}</FormHelperText>
                    )}
                  </Box>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="declaration"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={value}
                          onChange={e => onChange(e.target.checked)}
                        />
                      }
                      label="I declare that the information provided is true and complete"
                    />
                    {errors.declaration && (
                      <FormHelperText error>{errors.declaration.message}</FormHelperText>
                    )}
                  </Box>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="declarationDate"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type="date"
                    label="Declaration Date"
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.declarationDate}
                    helperText={errors.declarationDate?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        )}

        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
          <Button 
            variant="contained" 
            onClick={() => setActiveTab(Math.max(0, activeTab - 1))}
            disabled={activeTab === 0}
          >
            Previous
          </Button>
          {activeTab === 3 ? (
            <Button 
              variant="contained" 
              color="primary" 
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Submit Application'}
            </Button>
          ) : (
            <Button 
              variant="contained" 
              onClick={() => handleTabChange(null, activeTab + 1)}
            >
              Next
            </Button>
          )}
        </Box>
      </form>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default EmploymentApplicationForm;