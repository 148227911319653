import errorHandler from './utils/errorHandler';
import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { Box, Button, TextField, Typography, List, ListItem, ListItemText, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MuiAlert from '@mui/material/Alert';
import api from './axiosConfig';

const Reminders = () => {
    const [reminders, setReminders] = useState([]);
    const [formData, setFormData] = useState({
        subject: '',
        body: '',
        reminder_date: null,
    });
    const [selectedReminder, setSelectedReminder] = useState(null);
    const [rescheduleDate, setRescheduleDate] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

    useEffect(() => {
        fetchReminders();
    }, []);

    const fetchReminders = async () => {
    try {
        const response = await api.get('/reminders');
        const formattedReminders = response.data.map(reminder => ({
            ...reminder,
            reminder_date: format(new Date(reminder.reminder_date), 'yyyy-MM-dd')
        }));
        setReminders(formattedReminders);
    } catch (error) {
        console.error('Error fetching reminders:', error);
        console.error('Error details:', error.response ? error.response.data : error.message);
        setSnackbar({ open: true, message: 'Error fetching reminders', severity: 'error' });
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleDateChange = (date) => {
        setFormData({ ...formData, reminder_date: date });
    };

    const handleSubmit = async (event) => {
    event.preventDefault();
    const formattedData = {
        ...formData,
        reminder_date: formData.reminder_date ? format(new Date(formData.reminder_date), 'yyyy-MM-dd') : null
    };
    errorHandler.logApiCall('Submitting reminder:', formattedData);
    try {
        const response = await api.post('/reminders', formattedData);
        errorHandler.logDebug('Reminders', 'Reminder submission response:', response);
        setSnackbar({ open: true, message: 'Reminder submitted successfully!', severity: 'success' });
        setFormData({ subject: '', body: '', reminder_date: null });
        fetchReminders();
    } catch (error) {
        console.error('Error submitting reminder:', error);
        console.error('Error details:', error.response ? error.response.data : error.message);
        setSnackbar({ open: true, message: 'Error submitting reminder', severity: 'error' });
         }
    };

    const handleDismiss = async (id) => {
        try {
            await api.delete(`/reminders/${id}`);
            setSnackbar({ open: true, message: 'Reminder dismissed successfully', severity: 'success' });
            fetchReminders();
        } catch (error) {
            console.error('Error dismissing reminder:', error);
            console.error('Error details:', error.response ? error.response.data : error.message);
            setSnackbar({ open: true, message: 'Error dismissing reminder', severity: 'error' });
        }
    };

    const handleReschedule = (reminder) => {
        setSelectedReminder(reminder);
        setRescheduleDate(new Date(reminder.reminder_date));
        setOpenDialog(true);
    };

    const confirmReschedule = async () => {
        try {
            await api.put(`/reminders/${selectedReminder.id}`, { reminder_date: rescheduleDate });
            setSnackbar({ open: true, message: 'Reminder rescheduled successfully', severity: 'success' });
            setOpenDialog(false);
            fetchReminders();
        } catch (error) {
            console.error('Error rescheduling reminder:', error);
            console.error('Error details:', error.response ? error.response.data : error.message);
            setSnackbar({ open: true, message: 'Error rescheduling reminder', severity: 'error' });
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box className="main-content">
                <Typography variant="h4">Reminders</Typography>
                
                {/* Submit Reminder Form */}
                <Box className="form-container">
                    <form className="common-form" onSubmit={handleSubmit}>
                        <TextField
                            label="Subject"
                            name="subject"
                            value={formData.subject}
                            onChange={handleChange}
                            required
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Body"
                            name="body"
                            value={formData.body}
                            onChange={handleChange}
                            required
                            multiline
                            rows={4}
                            fullWidth
                            margin="normal"
                        />
                        <DatePicker
                            label="Reminder Date"
                            value={formData.reminder_date}
                            onChange={handleDateChange}
                            renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                            required
                        />
                        <Box className="form-actions" mt={2}>
                            <Button variant="contained" color="primary" type="submit">Submit Reminder</Button>
                        </Box>
                    </form>
                </Box>

                {/* View Reminders List */}
                <Typography variant="h5" mt={4}>Existing Reminders</Typography>
                <List>
                    {reminders.map((reminder) => (
                        <ListItem key={reminder.id}>
                            <ListItemText
                                primary={reminder.subject}
                                secondary={`${reminder.body} - ${new Date(reminder.reminder_date).toLocaleDateString()}`}
                            />
                            <Button onClick={() => handleDismiss(reminder.id)}>Dismiss</Button>
                            <Button onClick={() => handleReschedule(reminder)}>Reschedule</Button>
                        </ListItem>
                    ))}
                </List>

                {/* Reschedule Dialog */}
                <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                    <DialogTitle>Reschedule Reminder</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please select a new date for the reminder:
                        </DialogContentText>
                        <DatePicker
                            label="New Reminder Date"
                            value={rescheduleDate}
                            onChange={(newDate) => setRescheduleDate(newDate)}
                            renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
                        <Button onClick={confirmReschedule}>Confirm</Button>
                    </DialogActions>
                </Dialog>

                {/* Snackbar for notifications */}
                <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                    <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity={snackbar.severity}>
                        {snackbar.message}
                    </MuiAlert>
                </Snackbar>
            </Box>
        </LocalizationProvider>
    );
};

export default Reminders;