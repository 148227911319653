import errorHandler from './utils/errorHandler';
import React, { createContext, useState, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import api from './axiosConfig';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(() => {
        try {
            const savedUser = localStorage.getItem('user');
            return savedUser ? JSON.parse(savedUser) : null;
        } catch (error) {
            console.error('Error parsing stored user:', error);
            return null;
        }
    });
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const navigate = useNavigate();

    const login = useCallback(async (email, password) => {
        setIsLoggingIn(true);
        try {
            const response = await api.post('/auth/login', { email, password });
            errorHandler.logDebug('AuthContext', 'Login response:', response.data);
            
            // Immediately store the tokens and user data
            const { token, refresh_token, user: userData } = response.data;
            
            // Explicitly store each item and verify storage
            try {
                // Store access token
                localStorage.setItem('token', token);
                errorHandler.logDebug('AuthContext', 'Access token stored:', !!localStorage.getItem('token'));

                // Store refresh token
                localStorage.setItem('refresh_token', refresh_token);
                errorHandler.logDebug('AuthContext', 'Refresh token stored:', !!localStorage.getItem('refresh_token'));

                // Store user data
                const userToStore = {
                    ...userData,
                    role: userData.role || 'ACCOUNT_USER' // Fallback role if none provided
                };
                localStorage.setItem('user', JSON.stringify(userToStore));
                errorHandler.logDebug('AuthContext', 'User data stored:', !!localStorage.getItem('user'));

                // Update state
                setUser(userToStore);

                // Verify the role for navigation
                errorHandler.logDebug('AuthContext', 'User role for navigation:', userToStore.role);

                // Navigate based on role
                switch (userToStore.role) {
                    case 'ACCOUNT_MANAGER':
                        navigate('/manager_dashboard', { replace: true });
                        break;
                    case 'ACCOUNT_OWNER':
                        navigate('/owner_dashboard', { replace: true });
                        break;
                    case 'ACCOUNT_USER':
                        navigate('/user_dashboard', { replace: true });
                        break;
                    default:
                        navigate('/dashboard', { replace: true });
                }

                return { success: true, user: userToStore };
            } catch (storageError) {
                console.error('Storage error:', storageError);
                throw new Error('Failed to store authentication data');
            }
        } catch (error) {
            console.error('Login error:', error);
            return { 
                success: false, 
                error: error.response?.data?.error || 'Login failed' 
            };
        } finally {
            setIsLoggingIn(false);
        }
    }, [navigate]);

    const logout = useCallback(() => {
        try {
            localStorage.clear(); // Clear all storage
            setUser(null);
            navigate('/login');
        } catch (error) {
            console.error('Logout error:', error);
        }
    }, [navigate]);

    // Add a check for token expiration
    const checkAuth = useCallback(() => {
        const token = localStorage.getItem('token');
        const userData = localStorage.getItem('user');
        
        if (!token || !userData) {
            logout();
            return false;
        }
        return true;
    }, [logout]);

    return (
        <AuthContext.Provider value={{ 
            user, 
            login, 
            logout, 
            isLoggingIn,
            isAuthenticated: !!user && checkAuth(),
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export default AuthContext;