const errorHandler = {
    // For general error logging
    logError: (error, context = '') => {
        if (process.env.NODE_ENV !== 'production') {
            console.error(`[${context}]`, error);
        }
    },

    // For getting user-friendly error messages
    getErrorMessage: (error) => {
        if (process.env.NODE_ENV === 'production') {
            return 'An error occurred. Please try again later.';
        }
        return error.message || 'Unknown error occurred';
    },

    // For handling API errors
    handleApiError: (error, context = '') => {
        errorHandler.logError(error, context);
        
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    return 'Invalid request. Please check your input.';
                case 401:
                    return 'Please log in to continue.';
                case 403:
                    return 'You do not have permission to perform this action.';
                case 404:
                    return 'The requested resource was not found.';
                case 500:
                    return 'A server error occurred. Please try again later.';
                default:
                    return 'An unexpected error occurred. Please try again.';
            }
        }
        
        return 'Unable to connect to the server. Please check your connection.';
    },

    // For handling form validation errors
    handleFormError: (error) => {
        if (error.response?.data?.errors) {
            return error.response.data.errors;
        }
        return { general: errorHandler.getErrorMessage(error) };
    },

    // For tracking API calls
    logApiCall: (message, data = null) => {
        if (process.env.NODE_ENV !== 'production') {
            console.log(`[API] ${message}`, data || '');
        }
    },

    // For tracking component lifecycle
    logDebug: (component, message, data = null) => {
        if (process.env.NODE_ENV !== 'production') {
            console.log(`[${component}] ${message}`, data || '');
        }
    },

    // For tracking successful operations
    logSuccess: (component, message, data = null) => {
        if (process.env.NODE_ENV !== 'production') {
            console.log(`[${component}] ✓ ${message}`, data || '');
        }
    }
};

export default errorHandler;