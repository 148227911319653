import errorHandler from './utils/errorHandler';
import React, { useState, useEffect } from 'react';
import api from './axiosConfig';
import { 
  Box, 
  Grid, 
  Typography, 
  TextField, 
  Button, 
  Tabs, 
  Tab, 
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  CircularProgress
} from '@mui/material';
import RenterSearch from './RenterSearch';
import './styles.css';

const SpaceRentalCard = ({ userRole }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [rentalData, setRentalData] = useState({
    name: '',
    address1: '',
    address2: '',
    address3: '',
    postcode: '',
    phoneNumber: '',
    emergencyContactName: '',
    emergencyContactNumber: '',
    rentPeriod: '',
    rentCost: '',
    turnoverRentPercentage: '',
    dateRentalCommenced: '',
    invoiceOffset: ''
  });
  const [activeTab, setActiveTab] = useState(0);
  const [documents, setDocuments] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const fetchRentalData = async () => {
  errorHandler.logApiCall('Fetching rental data');
  try {
    const response = await api.get('/rentals/data');
    errorHandler.logDebug('SpaceRentalCard', 'Raw API response:', response);  // Add this
    errorHandler.logApiCall('Response data:', response.data);  // Add this
    
    if (response.data && !response.data.error) {
      const formattedDate = response.data.dateRentalCommenced ? 
        new Date(response.data.dateRentalCommenced).toISOString().split('T')[0] : '';

      const newRentalData = {
        name: response.data.name || '',
        address1: response.data.address1 || '',
        address2: response.data.address2 || '',
        address3: response.data.address3 || '',
        postcode: response.data.postcode || '',
        phoneNumber: response.data.phoneNumber || '',
        emergencyContactName: response.data.emergencyContactName || '',
        emergencyContactNumber: response.data.emergencyContactNumber || '',
        rentPeriod: response.data.rentPeriod || '',
        rentCost: response.data.rentCost || '',
        turnoverRentPercentage: response.data.turnoverRentPercentage || '',
        dateRentalCommenced: formattedDate,
        invoiceOffset: response.data.invoiceOffset || ''
      };

      errorHandler.logDebug('SpaceRentalCard', 'Setting rental data to:', newRentalData);  // Add this
      setRentalData(newRentalData);
      errorHandler.logDebug('SpaceRentalCard', 'Rental data set');  // Add this
    } else {
      errorHandler.logDebug('SpaceRentalCard', 'No rental data found or error:', response.data);  // Add this
    }
  } catch (error) {
    console.error('Error in fetchRentalData:', error);
    setError('Error fetching rental data');
  }
};

const handleRenterSelect = async (renter) => {
  try {
    if (!renter?.id) {
      console.error('No renter ID provided');
      return;
    }

    errorHandler.logDebug('SpaceRentalCard', 'Selected renter:', renter);
    
    setLoading(true);  // Add loading state while fetching

    // Fetch rental data
    const response = await api.get(`/rentals/data/${renter.id}`);
    
    if (response.data) {
      const formattedDate = response.data.dateRentalCommenced ? 
        new Date(response.data.dateRentalCommenced).toISOString().split('T')[0] : '';
        
      setRentalData({
        name: response.data.name || '',
        address1: response.data.address1 || '',
        address2: response.data.address2 || '',
        address3: response.data.address3 || '',
        postcode: response.data.postcode || '',
        phoneNumber: response.data.phoneNumber || '',
        emergencyContactName: response.data.emergencyContactName || '',
        emergencyContactNumber: response.data.emergencyContactNumber || '',
        rentPeriod: response.data.rentPeriod || '',
        rentCost: response.data.rentCost || '',
        turnoverRentPercentage: response.data.turnoverRentPercentage || '',
        dateRentalCommenced: formattedDate,
        invoiceOffset: response.data.invoiceOffset || ''
      });

      // Fetch associated documents and invoices
      await Promise.all([fetchDocuments(), fetchInvoices()]);
    }
  } catch (error) {
    console.error('Error fetching renter details:', error);
    alert('Error fetching renter details');
  } finally {
    setLoading(false);  // Clear loading state
  }
};

  const fetchDocuments = async () => {
    errorHandler.logApiCall('Fetching documents');
    try {
      const response = await api.get('/rentals/documents');
      setDocuments(response.data);
    } catch (error) {
      console.error('Error fetching documents:', error);
      setDocuments([]);
    }
  };

  const fetchInvoices = async () => {
    errorHandler.logApiCall('Fetching invoices');
    try {
      const response = await api.get('/rentals/invoices');
      setInvoices(response.data);
    } catch (error) {
      console.error('Error fetching invoices:', error);
      setInvoices([]);
    }
  };

  const fetchAllData = async () => {
  try {
    setLoading(true);
    errorHandler.logDebug('SpaceRentalCard', 'Starting to fetch all data');
    
    // Only fetch documents and invoices if we have rental data
    if (rentalData.name) {
      await Promise.all([fetchDocuments(), fetchInvoices()]);
      errorHandler.logDebug('SpaceRentalCard', 'All data fetched successfully');
    }
  } catch (err) {
    console.error('Error fetching data:', err);
    setError(err.message);
  } finally {
    setLoading(false);
  }
};

  useEffect(() => {
    errorHandler.logDebug('SpaceRentalCard', 'SpaceRentalCard mounted');
    fetchAllData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRentalData(prev => ({ ...prev, [name]: value }));
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    
    const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    
    if (!allowedTypes.includes(file.type)) {
      alert('This file type is not allowed. Please upload a PDF, JPEG, PNG, or Word document.');
      e.target.value = '';
      return;
    }
  
    setSelectedFile(file);
  
    const formData = new FormData();
    formData.append('document', file);
  
    try {
      const response = await api.post('/rentals/upload_document', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setDocuments([...documents, response.data]);
    } catch (error) {
      console.error('Error uploading document:', error);
      alert('Error uploading document. Please try again.');
    }
  };
  
  const handleSave = async () => {
  try {
    const response = await api.post('/rentals/save', rentalData);
    if (response.data.error) {
      alert('Error saving rental data: ' + response.data.error);
    } else {
      alert('Rental data saved successfully');
      // Clear the form
      setRentalData({
        name: '',
        address1: '',
        address2: '',
        address3: '',
        postcode: '',
        phoneNumber: '',
        emergencyContactName: '',
        emergencyContactNumber: '',
        rentPeriod: '',
        rentCost: '',
        turnoverRentPercentage: '',
        dateRentalCommenced: '',
        invoiceOffset: ''
      });
      // Refresh data
      fetchAllData();
    }
  } catch (error) {
    console.error('Error saving rental data:', error);
    alert('Error saving rental data. Please try again.');
  }
};
  
  const handleDownloadInvoice = (invoiceId) => {
    window.open(`/rentals/invoice/${invoiceId}`, '_blank');
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'accepted':
        return 'success';
      case 'denied':
        return 'error';
      default:
        return 'default';
    }
  };

  if (loading) return <Box display="flex" justifyContent="center" alignItems="center" height="100vh"><CircularProgress /></Box>;
  if (error) return <Box display="flex" justifyContent="center" alignItems="center" height="100vh"><Typography color="error">Error: {error}</Typography></Box>;


  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: 1200, margin: '0 auto' }}>
      {errorHandler.logDebug('SpaceRentalCard', 'Current rental data in render:', rentalData)}
      <Typography variant="h4" gutterBottom>Space Rental Card</Typography>
      
      
      <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)}>
        <Tab label="Rental Info" />
        <Tab label="Documents" />
        <Tab label="Invoices" />
      </Tabs>

      {activeTab === 0 && (
        <Box sx={{ mt: 3 }}>
        <RenterSearch onRenterSelect={handleRenterSelect} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={rentalData.name}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Address Line 1"
                name="address1"
                value={rentalData.address1}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Address Line 2"
                name="address2"
                value={rentalData.address2}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Address Line 3"
                name="address3"
                value={rentalData.address3}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Postcode"
                name="postcode"
                value={rentalData.postcode}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Phone Number"
                name="phoneNumber"
                value={rentalData.phoneNumber}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Emergency Contact Name"
                name="emergencyContactName"
                value={rentalData.emergencyContactName}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Emergency Contact Number"
                name="emergencyContactNumber"
                value={rentalData.emergencyContactNumber}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Rent Period"
                name="rentPeriod"
                value={rentalData.rentPeriod}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Rent Cost"
                name="rentCost"
                type="number"
                value={rentalData.rentCost}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Turnover Rent %"
                name="turnoverRentPercentage"
                type="number"
                value={rentalData.turnoverRentPercentage}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Date Rental Commenced"
                name="dateRentalCommenced"
                type="date"
                value={rentalData.dateRentalCommenced}
                onChange={handleChange}
                required
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Invoice Offset (days)"
                name="invoiceOffset"
                type="number"
                value={rentalData.invoiceOffset}
                onChange={handleChange}
                required
              />
            </Grid>
          </Grid>

          <Box sx={{ mt: 3 }}>
            <Button variant="contained" onClick={handleSave}>Save</Button>
          </Box>
        </Box>
      )}

      {activeTab === 1 && (
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6" gutterBottom>Documents</Typography>
          
          <TableContainer component={Paper} sx={{ mb: 3 }}>
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Document Name</TableCell>
        <TableCell>Upload Date</TableCell>
        <TableCell>Reminder Date</TableCell>
        <TableCell>Status</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {documents.map((doc, index) => (
        <TableRow key={index}>
          <TableCell>{doc.name}</TableCell>
          <TableCell>
            {doc.upload_date ? new Date(doc.upload_date).toLocaleDateString() : 'Invalid Date'}
          </TableCell>
          <TableCell>
            {doc.reminder_date ? new Date(doc.reminder_date).toLocaleDateString() : 'N/A'}
          </TableCell>
          <TableCell>
            <Chip 
              label={doc.status} 
              color={getStatusColor(doc.status)}
            />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>

          <Box sx={{ mt: 2 }}>
            <Button variant="contained" component="label">
              Upload Document
              <input 
                type="file" 
                hidden 
                onChange={handleFileUpload} 
                accept=".pdf,.jpeg,.jpg,.png,.doc,.docx"
              />
            </Button>
          </Box>
        </Box>
      )}

      {activeTab === 2 && (
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6">Invoices</Typography>
          <Grid container spacing={2}>
            {invoices.map((invoice, index) => (
              <Grid item xs={12} key={index}>
                <Paper sx={{ p: 2 }}>
                  <Typography>Invoice ID: {invoice.id}</Typography>
                  <Typography>Status: {invoice.status}</Typography>
                  <Button variant="outlined" onClick={() => handleDownloadInvoice(invoice.id)}>Print Invoice</Button>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Paper>
  );
};

export default SpaceRentalCard;