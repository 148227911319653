import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Grid, 
  TextField, 
  Button, 
  List, 
  ListItem, 
  ListItemText,
  Paper 
} from '@mui/material';
import api from './axiosConfig';

const RenterSearch = ({ onRenterSelect }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = async () => {
    try {
      if (!searchTerm.trim()) {
        return;
      }
      const response = await api.get(`/rentals/search?term=${searchTerm}`);
      setSearchResults(response.data);
    } catch (error) {
      console.error('Error searching renters:', error);
    }
  };

  const handleRenterClick = (renter) => {
    onRenterSelect(renter);
    // Clear search results and search term after selection
    setSearchResults([]);
    setSearchTerm('');
  };

  // Clear function for component unmount
  useEffect(() => {
    return () => {
      setSearchResults([]);
      setSearchTerm('');
    };
  }, []);

  return (
    <Box sx={{ mb: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={10}>
          <TextField
            fullWidth
            label="Find Renters"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button variant="contained" onClick={handleSearch}>Search</Button>
        </Grid>
      </Grid>
      
      {/* Show results only if there's a search term and results */}
      {searchResults.length > 0 && searchTerm && (
        <Paper sx={{ mt: 2 }}>
          <List>
            {searchResults.map((renter) => (
              <ListItem 
                key={renter.id} 
                button 
                onClick={() => handleRenterClick(renter)}
              >
                <ListItemText 
                  primary={renter.name}
                  secondary={`${renter.address1}, ${renter.postcode}`}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
    </Box>
  );
};

export default RenterSearch;